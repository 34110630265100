.BuilderMain1 {
  
  font-family: Montserrat;
  color: white;
  
  align-items: center;
  font-size: medium;

  





}




@media (max-width: 768px) {

  .card {
    width: 100%;

  }
}





.table {

color:black;

font-size: medium;
font-weight: normal;



border-color: rgb(88, 88, 88);



}



body {


background-color: #e8e1eb;



padding: 0px;





  background-position: top;
  background-repeat:repeat ;
 background-size:cover;
   

 
 

  align-items: center;
  
color:white;  
  



}


.App-header {

  background-color: #e8e1eb;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top-center;
  font-size: medium;
  color: white;
}






.pointer         { cursor: pointer; }




.card
 
{
 
width: 360px;

background-color:white;




 
border-color: rgb(194, 192, 192);

border-width: 0.5px;


font-family: Montserrat;  

border-radius: 15px;




font-size: medium;
font-weight: normal;





}


.card-body {

  
  

  background-color:transparent;


  white-space: nowrap;
overflow: auto;
text-overflow: ellipsis;




}


.card-header {


color:whitesmoke;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}















 













.btn {

  display: block;
  width:100%;

background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
border-radius: 8px;
border-style: none;
box-sizing: border-box;
color: #FFFFFF;
cursor: pointer;
flex-shrink: 0;
font-family: Montserrat;

font-size: 16px;
font-weight: 700;
height: 3rem;
padding: 0 0.7rem;
text-align: center;
text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
transition: all .5s;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;



}

.btn:hover {
box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
transition-duration: .1s;
color: #FFFFFF;



}

@media (max-width: 768px) {
.btn {
  padding: 0 2.6rem;
}
}


